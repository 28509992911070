import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";
  export default {
    fillable_by:FillableBy.Both,
    title:
      "KPS Normothermic Regional Perfusion (NRP) Tech Competency",
    sections: [
      // {...employeeinfo},
      {
        title: "COGNITIVE NRP TRAINING AND COMPETENCY DEMONSTRATION",
        type: "table",
        heads: constants.tableHeads,
        rows:[
            "General OR attire, conduct, and sterility maintenance",
            "Describe purpose of NRP and the hallmark events that define it",
            "Detail the Donor OR setup process",
            "Explain cannulation steps for: A-NRP, TA-NRP, Hybrid A-NRP",
            "Identify all components of NRP circuit",
            "Setup NRP equipment and disposables",
            "Assisting in blended technology cases (e.g., SRR & NRP, Organ machine perfusion & NRP)",
            "NRP case documentation in Keyport",
            "Equipment breakdown and cleaning"
        ].map((item) => ([ {text: item, type: 'text' }, ...table_fields ])),
      },
      {...evaluationCheckList},
      {
        title: "Signatures",
        type: "signature",
        fields: getSignatures(FillableBy.Both)
      },
    ]
  }